import {Helmet} from 'react-helmet-async';
// sections
import ConfirmRegisterView from "src/sections/auth/confirm-register-view";
// ----------------------------------------------------------------------

export default function ConfirmRegisterPage() {
  return (
    <>
      <Helmet>
        <title>Confirm Register</title>
      </Helmet>

      <ConfirmRegisterView/>
    </>
  );
}
