// routes
export const STORAGE_KEY = {
  USER_ACCESS_TOKEN: 'accessToken',
  USER_REFRESH_TOKEN: 'refreshToken',
  TENANT_ACCESS_TOKEN: 'tenantAccessToken',
  TENANT_REFRESH_TOKEN: 'tenantRefreshToken',
  TENANT_BUSINESS_ID: 'managementBusinessId',
};

// ----------------------------------------------------------------------

function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export const setSession = (accessToken: string | null, refreshToken?: string | null) => {
  if (accessToken && refreshToken) {
    localStorage.setItem(STORAGE_KEY.USER_ACCESS_TOKEN, accessToken);
    localStorage.setItem(STORAGE_KEY.USER_REFRESH_TOKEN, refreshToken);
  } else {
    localStorage.removeItem(STORAGE_KEY.USER_ACCESS_TOKEN);
    localStorage.removeItem(STORAGE_KEY.USER_REFRESH_TOKEN);
  }
};

export const setTenantSession = (accessToken: string | null, refreshToken?: string | null) => {
  if (accessToken && refreshToken) {
    localStorage.setItem(STORAGE_KEY.TENANT_ACCESS_TOKEN, accessToken);
    localStorage.setItem(STORAGE_KEY.TENANT_REFRESH_TOKEN, refreshToken);
  } else {
    localStorage.removeItem(STORAGE_KEY.TENANT_ACCESS_TOKEN);
    localStorage.removeItem(STORAGE_KEY.TENANT_REFRESH_TOKEN);
  }
};

export const resetTenantSession = () => {
  localStorage.removeItem(STORAGE_KEY.TENANT_BUSINESS_ID);
  localStorage.removeItem(STORAGE_KEY.TENANT_ACCESS_TOKEN);
  localStorage.removeItem(STORAGE_KEY.TENANT_REFRESH_TOKEN);
};
