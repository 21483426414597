import {useCallback, useEffect} from 'react';
// @mui
import Button from "@mui/material/Button";

// hooks
import {useBoolean} from 'src/hooks/use-boolean';
// routes
import {useSearchParams, useRouter} from 'src/routes/hooks';
// config
// auth
import {useAuthContext} from 'src/auth/hooks';
import {PATH_AFTER_LOGIN} from "../../config-global";
import {ConfirmDialog} from "../../components/custom-dialog";
// components


// ----------------------------------------------------------------------

export default function ConfirmRegisterView() {
  const {confirmRegister} = useAuthContext();
  const searchParams = useSearchParams();
  const managementUserID = searchParams.get('managementUserID');
  const confirmationID = searchParams.get('confirmationID');
  const username = searchParams.get('username');
  const email = searchParams.get('email');
  const verifyToken = searchParams.get('verifyToken');
  const allParams = Object.fromEntries(searchParams);
  const router = useRouter();
  const confirm = useBoolean();

  const handleConfirmRegister = useCallback(async () => {

    try {
      await confirmRegister?.(managementUserID || "",
        confirmationID || "",
        username || "",
        email || "",
        verifyToken || "");

      router.push(PATH_AFTER_LOGIN);

    } catch (e) {
      console.log(e)
      confirm.onTrue()
      router.push(PATH_AFTER_LOGIN)
    }

    console.log(allParams);

  }, [
    managementUserID,
    confirmationID,
    username,
    email,
    verifyToken,
    confirmRegister,
    router,
    allParams,
    confirm])

  useEffect(() => {
    handleConfirmRegister();
  }, [handleConfirmRegister])


  return (
    <>
      Confirm Register...
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error">
            Okay!
          </Button>
        }
      />
    </>
  );
}
