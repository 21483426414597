import * as openpgp from 'openpgp';

export type EncryptionKeysType = {
  id: string;
  pk: string;
};

export const encrypt = async (b64Key: string, body: any) => {
  const pubKey = await openpgp.readKey({
    armoredKey: atob(b64Key),
  });

  const tolerantDate = new Date(Date.now() + 10000);
  const encrypted = await openpgp.encrypt({
    message: await openpgp.createMessage({
      text: JSON.stringify(body),
    }),
    encryptionKeys: pubKey,
    date: tolerantDate,
  });

  return btoa(String(encrypted));
};
