import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify';
// auth
import { useAuthContext } from 'src/auth/hooks';
// locales
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  store: icon('ic_store'),
  employee: <Iconify icon="solar:users-group-rounded-bold-duotone" width={24} />,
  profile: <Iconify icon="solar:shield-user-bold-duotone" width={24} />,
  branch: <Iconify icon="solar:shop-bold-duotone" width={24} />,
  device: <Iconify icon="solar:devices-bold-duotone" width={24} />,
  product: <Iconify icon="solar:box-bold-duotone" width={24} />,
  country: <Iconify icon="solar:earth-bold-duotone" width={24} />,
  region: <Iconify icon="solar:point-on-map-bold-duotone" width={24} />,
  category: <Iconify icon="solar:widget-add-bold-duotone" width={24} />,
  marketing: <Iconify icon="solar:volume-bold-duotone" width={24} />,
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const { business } = useAuthContext();

  const data = useMemo(
    () => [
      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: business?.title || '',
        items: [
          { title: t('overview'), path: paths.management.root, icon: ICONS.analytics },
          {
            title: t('branch'),
            path: paths.management.branch.root,
            icon: ICONS.branch,
            children: [
              { title: 'list', path: paths.management.branch.root },
              { title: 'create', path: paths.management.branch.create },
            ],
          },
          {
            title: t('business_item'),
            path: paths.management.businessItem.root,
            icon: ICONS.product,
            children: [
              { title: 'list', path: paths.management.businessItem.root },
              { title: 'create', path: paths.management.businessItem.create },
            ],
          },
          {
            title: t('marketing'),
            path: paths.management.marketing.root,
            icon: ICONS.marketing,
          },
          {
            title: t('category'),
            path: paths.management.category.root,
            icon: ICONS.category,
            children: [
              { title: 'list', path: paths.management.category.root },
              { title: 'create', path: paths.management.category.create },
            ],
          },
          {
            title: t('country'),
            path: paths.management.country.root,
            icon: ICONS.country,
            children: [
              { title: 'list', path: paths.management.country.root },
              { title: 'create', path: paths.management.country.create },
            ],
          },
          {
            title: t('region'),
            path: paths.management.region.root,
            icon: ICONS.region,
            children: [
              { title: 'list', path: paths.management.region.root },
              { title: 'create', path: paths.management.region.create },
            ],
          },
          // {
          //   title: t('pos_device'),
          //   path: paths.management.posDevice.root,
          //   icon: ICONS.device,
          //   children: [
          //     { title: 'list', path: paths.management.posDevice.root },
          //     { title: 'create', path: paths.management.posDevice.create },
          //   ],
          // },
        ],
      },
    ],
    [t, business]
  );

  return data;
}
