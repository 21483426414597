import { forwardRef } from 'react';
// @mui
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { TransitionProps } from '@mui/material/transitions';
// hooks
import { FormDialogProps } from './types';
import Iconify from '../iconify';

// ----------------------------------------------------------------------

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

export default function FormDialog({
  title,
  content,
  action,
  open,
  onClose,
  disableClosing,
  children,
  actionAppBar,
  ...other
}: FormDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={() => {
        if (disableClosing) return;

        onClose();
      }}
      {...(other?.fullScreen && { TransitionComponent: Transition })}
      {...other}
    >
      {other.fullScreen ? (
        <AppBar position="relative" color="default">
          <Toolbar>
            <IconButton color="inherit" edge="start" onClick={onClose}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>

            <Typography variant="h6" sx={{ flex: 1, ml: 2 }}>
              {title}
            </Typography>

            {actionAppBar}
          </Toolbar>
        </AppBar>
      ) : (
        <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>
      )}

      <DialogContent dividers={other.scroll === 'paper'}>{children}</DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>

        {action}
      </DialogActions>
    </Dialog>
  );
}
