import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
// components
import Iconify from 'src/components/iconify';
import { useLocales } from '../../locales';
// import SvgColor from '../../components/svg-color';

// ----------------------------------------------------------------------

export function useNavConfig() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------

      // {
      //   title: t('dashboard'),
      //   icon: <Iconify icon="material-symbols:assignment-outline" />,
      //   path: PATH_AFTER_LOGIN,
      // },
      {
        title: t('my_business'),
        icon: <Iconify icon="solar:buildings-2-bold-duotone" />,
        path: paths.dashboard.business.root,
      },
      // {
      //   title: t('analytics'),
      //   icon: <SvgColor src="/assets/icons/navbar/ic_analytics.svg" sx={{ width: 20, height: 20 }} />,
      //   path: paths.dashboard.analytics,
      // },
    ],
    [t]
  );
  return data;
}
