import { useMemo } from 'react';
import useSWR from 'swr';
import originAxios, { AxiosResponse } from 'axios';
import axios, { endpoints, fetcher } from 'src/utils/axios';
import { IBusinessDataRequestBody, IBusinessItemData, IBusinessItemsData } from '../types/business';
import { HOST_API, MAPBOX_API } from '../config-global';
import { ILocationMapbox } from '../components/map';
import { encrypt, EncryptionKeysType } from '../utils/pgp-encryption';

export function useGetBusinesses() {
  const URL = endpoints.business.me;

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher.managementFetcher);

  const memoizedValue = useMemo(
    () => ({
      businesses: data as IBusinessItemsData,
      businessesLoading: isLoading,
      businessesError: error,
      businessesValidating: isValidating,
      businessesEmpty: !isLoading && !data?.data.length,
      businessesMutate: mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function createBusiness(data: IBusinessDataRequestBody) {
  const encryptionKeys = await requestRegister();

  const encrypted = await encrypt(encryptionKeys.data.data.pk, data);
  const URL = endpoints.business.register;

  const res = await axios.managementInstance.post(URL, {
    id: encryptionKeys.data.data.id,
    ct: encrypted,
    pk: encryptionKeys.data.data.pk,
  });

  return res;
}

export async function createBusinessTenantSession(managementBusinessId: string) {
  const URL = endpoints.business.tenantSession;

  const res = await axios.managementInstance.post(URL, {
    managementBusinessId,
  });

  return res;
}

export async function updateBusiness(title: string) {
  const encryptionKeys = await requestUpdate();

  const encrypted = await encrypt(encryptionKeys.data.data.pk, { title });
  const URL = `${HOST_API}${endpoints.business.update}`;

  return axios.businessTenantInstance.put(URL, {
    id: encryptionKeys.data.data.id,
    ct: encrypted,
    pk: encryptionKeys.data.data.pk,
  });
}

export async function deleteBusiness(token: string) {
  const URL = `${HOST_API}${endpoints.business.remove}`;

  const res = await originAxios.delete(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
}

export function useGetBusinessDetail() {
  const URL = endpoints.business.info;
  const { data, isLoading, error, isValidating, mutate } = useSWR(
    URL,
    fetcher.businessTenantFetcher
  );

  const memoizedValue = useMemo(
    () => ({
      business: data as IBusinessItemData,
      businessLoading: isLoading,
      businessError: error,
      businessValidating: isValidating,
      businessEmpty: !isLoading && !data?.data.length,
      businessMutate: mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );
  return memoizedValue;
}

export async function searchLocation(location: string | null, session?: string) {
  const URL = `${MAPBOX_API.searchBoxUrl}${endpoints.location.suggest}`;

  const res: AxiosResponse<{ suggestions: ILocationMapbox[] }> = await originAxios.get(URL, {
    params: {
      q: location,
      access_token: MAPBOX_API.apiKey,
      session_token: session,
      language: 'en',
      limit: 10,
      poi_category: 'food,food and drink,restaurant',
    },
  });

  return res;
}

export async function retrieveLocation(id?: string, session?: string) {
  const URL = id ? `${MAPBOX_API.searchBoxUrl}${endpoints.location.retrieve}/${id}` : '';

  const res = await originAxios.get(URL, {
    params: {
      access_token: MAPBOX_API.apiKey,
      session_token: session,
      language: 'en',
    },
  });

  return res;
}

export async function requestRegister() {
  const URL = `${endpoints.business.requestRegister}`;

  const res = await axios.managementInstance.get<{ data: EncryptionKeysType }>(URL);

  return res;
}

export async function requestUpdate() {
  const URL = `${endpoints.business.requestUpdate}`;

  const res = await axios.businessTenantInstance.get<{ data: EncryptionKeysType }>(URL);

  return res;
}
