import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import MainLayout from 'src/layouts/main';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const PageMyBusiness = lazy(() => import('src/pages/dashboard/business/list'));
const PageCreateBusiness = lazy(() => import('src/pages/dashboard/business/create'));
const PageEditBusiness = lazy(() => import('src/pages/dashboard/business/edit'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <MainLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      </AuthGuard>
    ),
    children: [
      { element: <PageMyBusiness />, index: true },
      {
        path: 'business',
        children: [
          {
            path: ':id',
            children: [{ path: 'edit', element: <PageEditBusiness /> }],
          },
          { element: <PageMyBusiness />, index: true },
          { path: 'create', element: <PageCreateBusiness /> },
        ],
      },
      { path: 'analytics', element: <>analytics</> },
    ],
  },
];
